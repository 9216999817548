.divServicios {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 140vh;
  background-color: #F8F8F8;
}

.rayaServicios {
  width: 13vw;
  height: 2vh;
  background-color: rgb(87,139,208);
}

.titleServices {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 9vh;
  width: 100%;
  height: 10vh;
  font-size: 3vw;
}

.divAcc {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  margin-top: 12vh;
  width: 60%;
  /* height: 50vh; */
}

.titleAcordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3vw;
  width: 100%;
  height: 5vh;
  background-color: #f5f5f5;
  border: 1px solid white;
  font-size: 1vw;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.descAcordion {
  /* padding: 2vh 2vw 2vh 2vw; */
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;
  /* text-align: justify; */
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

.plusminus {
  margin-right: 5vh;
  position: relative;
  width: 1vw;
  height: 0.5vh;
  cursor: pointer;
}

.plusminus.active:before {
  transform: translatey(-50%) rotate(-90deg);
  opacity: 0;
}

.plusminus.active:after {
  transform: translatey(-50%) rotate(0);
}

.plusminus:before,
.plusminus:after {
  content: "";
  display: block;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 0;
  transition: 0.35s;
  width: 100%;
  height: 3px;
}

.plusminus:before {
  transform: translatey(-50%);
}

.plusminus:after {
  transform: translatey(-50%) rotate(90deg);
}

@media (max-width: 414px) {
  .titleServices {
    font-size: 4vh;
  }

  .divServicios{
    height: 120vh;
  }

  .rayaServicios {
    width: 36vw;
    height: 2vh;
    background-color: rgb(87,139,208);
  }
}

@media (max-width: 375px) {
  .divServicios{
    height: 160vh;
  }
}