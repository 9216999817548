.divSedes {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #F8F8F8;
}

.titleSedes {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 9vh;
    width: 100%;
    height: 10vh;
    font-size: 3vw;
}

.rayaSedes {
    width: 13vw;
    height: 2vh;
    background-color: rgb(87, 139, 208);
    /* margin-top: -4vh; */
}

.contSedes {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 60vh;
}

.sede {
    width: 30vw;
    height: 30vh;
    background: hsl(0 0% 100%);
    border-radius: 1vw;
    box-shadow: 0 1rem 2rem hsl(0 0% 0% / 20%);
}

.titleSede {
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;
    margin-top: 5vh;
    width: 100%;
    font-size: 2vw;
    font-weight: bold;
}

.fotoCont {
    display: flex;
    height: 50%;
    align-items: center;
    justify-content: center;
    margin-left: -1vw;
    font-size: 1vw;
}

.fotoSede {
    width: 110px;
    height: 110px;
    background-image: url('../../assets/image4.png');
    border-radius: 50%;
    background-size: cover;
}

.fotoSede2 {
    width: 110px;
    height: 110px;
    background-image: url('../../assets/image11.jpeg');
    border-radius: 50%;
    background-size: cover;
}

.fotoSede3 {
    width: 110px;
    height: 110px;
    background-image: url('../../assets/image5.png');
    border-radius: 50%;
    background-size: cover;
}

.dirSede {
    height: auto;
    margin-left: 1vw;
}

@media (max-width: 440px) {
    .titleSedes {
        margin-top: 5vh;
        font-size: 4vh;
    }

    .rayaSedes {
        width: 30vw;
    }

    .divSedes {
        height: 50rem;
    }

    .contSedes {
        flex-direction: column;
        margin-top: 5vh;
        /* height: 60vh; */
    }

    .sede1 {
        width: 95vw;
        height: 25vh;
        border-radius: 1.5vh;
    }

    .sede2 {
        width: 95vw;
        height: 25vh;
        border-radius: 1.5vh;
    }

    .titleSede {
        font-size: 4vh;
        margin-top: 2vh;
    }

    .sede {
        width: 95%;
        height: 16rem;
        margin-top: 1rem;
    }

    .dirSede {
        font-size: 0.8rem;
    }
}