.DivNosotros {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F8F8F8;
}

.contTitulo {
    display: flex;
    align-items: center;
    padding-left: 8vw;
    width: 90%;
    height: 5vh;
    margin-top: 15vh;
}

.raya {
    margin-right: 1vw;
    width: 0.3vw;
    height: 4vh;
    background-color: rgb(87,139,208);
}

.titleSobreNosotros {
    font-size: 2vw;
    font-weight: bold;
}

.ContNosotrosInfo {
    display: flex;
    justify-content: center;
    padding-top: 8vh;
    width: 100%;
    height: 50vh;
}

.imgNosotros {
    width: 33.5vw;
    height: 43.5vh;
    background-image: url('../../assets/img3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3vw 0px 3vw 0px;
}

.infoNosotros {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8.5vw;
    width: 38.5vw;
    height: 43.5vh;
}

.titleNosotros {
    font-size: 4vw;
    font-weight: bold;
}

.descNosotros {
    margin-top: 5vh;
    font-size: 1.3vw;
    text-align: justify;
}

.descInfoLegal {
    display: flex;
    align-items: center;
    padding-left: 8vw;
    width: 90%;
    height: 10vh;
}

.imgPolitica {
    width: 33.5vw;
    height: 43.5vh;
    background-image: url('../../assets/img10.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3vw 0px 3vw 0px;
}

.secMision {
    margin-top: 33vh;
    display: flex;
    width: 100%;
}

.imgMision {
    width: 60%;
    height: 90vh;
    background-image: url('../../assets/img8.jpg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}

.contMision {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 4vw 0px 4vw;
    width: 50.2%;
    height: 90vh;
    /* background-image: url('../../assets/maptransparent.png'); */
    background-repeat: no-repeat;
    background-size: cover;
}

.titleMision {
    display: flex;
    flex-direction: column;
    width: 19vw;
    height: 14vh;
    font-size: 6vw;
    font-weight: bold;
    z-index: 5;
}

.borderBottom {
    width: 14vw;
    height: 2.5vh;
    background-color: rgb(87,139,208);;
    top: -4vh;
    position: relative;
    z-index: 1;
}

.descMision {
    display: flex;
    align-items: center;
    width: 90%;
    height: 49vh;
    font-size: 1.5vw;
    text-align: justify;
    word-spacing: 0.3vw;
    letter-spacing: 0.1vw;
}

.secVision {
    /* margin-top: 6vh; */
    display: flex;
    width: 100%;
    height: 100vh;
}

.imgVision {
    width: 60%;
    height: 90vh;
    background-image: url('../../assets/img9.jpg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}

.contObjectives {
    display: flex;
    margin-top: 10vh;
    width: 100%;
    /* height: 80vh; */
    /* background-color: rgba(255, 0, 0, 0.119); */
}

.incrementar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50vh;
    background-color: #0056ff;
}

.imgIncrementar {
    width: 15vw;
    height: 19vh;
    background-image: url('../../assets/incrementar.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.textIncrementar {
    margin-top: 5vh;
    font-size: 1.3vw;
    color: white;
}

.oportunidad {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50vh;
    background-color: #141477;
}

.imgOportunidad {
    width: 15vw;
    height: 19vh;
    background-image: url('../../assets/oportunidad.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.textOportunidad {
    margin-top: 5vh;
    font-size: 1.3vw;
    color: white;
}

.competencias {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50vh;
    background-color: #020048;
}

.imgCompetencias {
    width: 15vw;
    height: 19vh;
    background-image: url('../../assets/competencias.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.textCompetencias {
    margin-top: 5vh;
    font-size: 1.3vw;
    color: white;
}

.clima {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50vh;
    background-color: #0341bd;
}

.imgClima {
    width: 15vw;
    height: 19vh;
    background-image: url('../../assets/clima.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.textClima {
    margin-top: 5vh;
    font-size: 1.3vw;
    color: white;
}

.contTitleValores {
    margin-top: 15vh;
    margin-left: 8vw;
}

.titleValores {
    font-size: 3vw;
    font-weight: bold;
}

.rayaValores {
    width: 16vw;
    height: 2vh;
    background-color: rgb(87,139,208);
}

.SplideBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
}

.titleVoluntad {
    display: flex;
    justify-content: center;
    font-size: 1.5vw;
    font-weight: bold;
    height: 6vh;
}

.imgValor {
    /* margin-left: 3.5vw; */
    width: 16vw;
    height: 25vh;
    background-image: url('../../assets/valor.png');
    background-repeat: no-repeat;
    background-size: 73% 90%;
    background-position: center;
}

.descValor {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* margin-left: 3.5vw; */
    width: 70%;
    text-align: justify;
    height: 13vh;
}

.boxSpl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 414px) {
    .contTitulo {
        margin-top: 5vh;
        padding-left: 8.5vw;
    }

    .titleSobreNosotros {
        font-size: 3.5vh;
    }

    .raya {
        margin-right: 2.2vw;
        width: 1.1vw;
    }

    .ContNosotrosInfo {
        flex-direction: column;
        align-items: center;
        height: 80vh;
    }

    .imgNosotros {
        width: 80vw;
        border-radius: 4vh 0px 4vh 0px;
    }

    .infoNosotros {
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-left: 0;
    }

    .titleNosotros {
        margin-top: 5vh;
        font-size: 3.5vh;
    }

    .descNosotros {
        margin-top: 5vh;
        height: 30vh;
        width: 90vw;
        font-size: 2vh;
    }

    .descInfoLegal {
        margin-top: 1vh;
        font-size: 2vh;
    }

    .imgPolitica {
        width: 80vw;
        border-radius: 4vh 0px 4vh 0px;
    }

    .secMision {
        flex-direction: column;
    }

    .imgMision {
        width: 100%;
        height: 20vh;
    }

    .contMision {
        width: 100%;
        height: 30vh;
        align-items: center;
    }

    .titleMision {
        margin-top: 2vh;
        font-size: 3.5vh;
    }

    .borderBottom {
        top: -3vh;
    }

    .descMision {
        font-size: 2vh;
    }

    .secVision {
        flex-direction: column;
        height: 50vh;
    }

    .titleMision {
        margin-top: 2vh;
    }

    .imgVision {
        width: 100%;
        height: 20vh;
    }

    .incrementar {
        height: 30vh;
    }

    .textIncrementar {
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.5vh;
    }

    .oportunidad {
        height: 30vh;
    }

    .textOportunidad {
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.5vh;
    }

    .competencias {
        height: 30vh;
    }

    .textCompetencias{
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.5vh;
    }

    .clima{
        height: 30vh;
    }

    .textClima{
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.5vh;
    }

    .titleValores{
        font-size: 3.5vh;
    }

    .rayaValores{
        width: 35vw;
        height: 1.5vh;
    }

    .imgValor{
        width: 58vw;
    }

    .descValor{
        font-size: 2vh;
    }

    .titleVoluntad{
        font-size: 2vh;
    }
}