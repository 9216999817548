.divFormatos{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 140vh;
    background-color: #F8F8F8;
}

.accFormatos{
    display: flex;
    flex-direction: column;
    margin-top: 12vh;
    width: 60%;
}

.rayaFormatos {
    width: 6vw;
    height: 2vh;
    background-color: rgb(87,139,208);
}

.titleFormatos {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 9vh;
    width: 100%;
    height: 10vh;
    font-size: 3vw;
}

@media (max-width: 414px) {

    .divFormatos{
        height: 80vh;
    }

    .titleFormatos{
        font-size: 4vh;
    }

    .accFormatos{
        width: 95%;
    }

    .rayaFormatos{
        width: 20vw;
        height: 1.5vh;
    }
}