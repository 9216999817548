.App {
  background-color: white;
  height: 100vh;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 23vh;
  width: 100%;
  /* background-color: rgb(225, 225, 225); */
  background: linear-gradient(to right, #ffffff, rgb(87,139,208), rgba(0, 0, 42));
}

.infoCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  height: 65%;
  /* background-color: red; */
}

.menuCont {
  display: flex;
  width: 85%;
  /* height: 38%; */
  height: 9vh;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: -4vh;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 0px 90px 90px;
  letter-spacing: 0.1vw;
  z-index: 10;
  transition: all 0.5s ease-out;
  box-shadow: 2px 3px 46px -7px rgba(0, 0, 0, 0.75);
}

.logo {
  width: 30vw;
  height: 16vh;
  background-image: url('./assets/comexpalLogo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.contInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 52vw;
  height: 100%;
  color: white;
  /* background-color: rgba(255, 0, 0, 0.387); */
}

.locationIcon {
  width: 3vw;
  height: 6vh;
  background-image: url('./assets/location.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.locationText {
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
}

.telIcon {
  margin-left: 1.5vw;
  width: 3vw;
  height: 6vh;
  background-image: url('./assets/telIcon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.telText {
  margin-left: 1vw;
  display: flex;
  flex-direction: column;
}

.facebook {
  margin-left: 1vw;
  width: 2.2vw;
  height: 6vh;
  background-image: url('./assets/facebook.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.instagram {
  margin-left: 1vw;
  width: 2.2vw;
  height: 6vh;
  background-image: url('./assets/instagram.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.twitter {
  margin-left: 1vw;
  width: 2.2vw;
  height: 6vh;
  background-image: url('./assets/twitter.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.opInicio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9vw;
  height: 100%;
  font-size: 1vw;
}

.opNosotros {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9vw;
  height: 100%;
  font-size: 1vw;
}

.opServicios {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9vw;
  height: 100%;
  font-size: 1vw;
}

.opContacto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9vw;
  height: 100%;
  font-size: 1vw;
}

span {
  cursor: pointer;
}

.footerCont {
  display: flex;
  width: 100%;
  padding-top: 10vh;
  background-color: white;
}

.produInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 1.3vw;
}

.row {
  margin-top: 1.8vh;
}

.creativeC {
  margin-top: 11vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
  background-color: black;
  color: white;
}

.logoMobile{
  margin-top: 2vh;
  margin-left: 50vw;
  width: 32vw;
  height: 8vh;
  background-image: url('./assets/comexpalLogo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
}

nav {
  display: flex;
  align-items: center;
  background: white;
  height: 16vh;
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  z-index: 10;
}

a {
  text-decoration: none;
  color: #1E1E23;
  opacity: 1;
  /* font-family: 'work sans', sans serif; */
  font-size: 1.5em;
  font-weight: 400;
  transition: 200ms;
}

a:hover {
  opacity: 0.5;
}

ul {
  padding: 0;
  list-style-type: none;
}

#menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 2vh;
  left: 4vh;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 6px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #36383F;
}

#menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 180px;
  height: 400px;
  box-shadow: 0 0 10px #85888C;
  margin: -50px 0 0 -50px;
  padding: 50px;
  padding-top: 12vh;
  background-color: #F5F6FA;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu li {
  padding: 10px 0;
  transition-delay: 2s;
}

#menuToggle input:checked~ul {
  transform: none;
}

/* Mobile css */

@media (max-width: 440px) {

  nav{
    background: linear-gradient(to right, rgba(0, 0, 42), rgb(87,139,208), #ffffff);
  }

  .row{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 75%;
    height: 5vh;
    font-size: 1.5vh;
    margin-top: 0.5vh;
  }

  .creativeC{
    margin-top: 2vh;
    height: 8vh;
    font-size: 1.5vh;
  }
}