.buttonInicio {
  margin-top: 42vh;
  margin-left: 7vw;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.35vw;
  border: 0.5px solid white;
  color: white;
  width: 13vw;
  height: 8vh;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 1;
  border-radius: 17px 0px 17px 0px;
  cursor: pointer;
}

.img1 {
  width: 100vw;
  height: 100%;
  background-image: url('../../assets/img1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation-duration: 8.59s;
  animation-name: scalein;
  animation-iteration-count: infinite;
  filter: sepia(50%) brightness(0.4);
}

.img2 {
  width: 100vw;
  height: 100%;
  background-image: url('../../assets/img2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation-duration: 8.59s;
  animation-name: scalein;
  animation-iteration-count: infinite;
  filter: sepia(50%) brightness(0.4);
}

.img3 {
  width: 100vw;
  height: 100%;
  background-image: url('../../assets/img4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  animation-duration: 8.59s;
  animation-name: scalein;
  animation-iteration-count: infinite;
  filter: sepia(50%) brightness(0.4);
}


@keyframes scalein {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.02);
  }
}

.fraseInicio {
  margin-top: 16vh;
  margin-left: 7vw;
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 4vw;
  position: absolute;
  z-index: 1;
}

#sec2 {
  overflow-x: hidden;
  background-image: url('../../assets/img5.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

#sec3 {
  overflow-x: hidden;
  background-image: url('../../assets/img6.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

#sec4 {
  overflow-x: hidden;
  background-image: url('../../assets/img7.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

.diagonal {
  width: 100%;
  height: 90vh;
  background: linear-gradient(123deg, rgb(31, 31, 116) 80%, rgba(0, 0, 42, 0.118) 100%);
  transform: skew(0.6rad);
  margin-left: -35vw;
}


.contServicios {
  width: 70vh;
  height: 50vh;
  transform: skew(-0.6rad);
  margin-left: 50vw;
  padding-top: 25vh;
  color: white;
  letter-spacing: 0.05vw;
}

.titleServicios {
  font-size: 5.5vw;
  font-weight: bold;
  color: rgb(87,139,208);
}

.titleServicio {
  margin-top: 2vh;
  font-size: 2.3vw;
  font-weight: 400;
}

.descServicio {
  margin-top: 2vh;
  font-size: 1.5vw;
  text-align: justify;
  word-spacing: 0.08vw;
}

.diagonal2 {
  width: 90%;
  height: 90vh;
  background-color: rgb(0, 0, 42);
  background: linear-gradient(55.5deg, rgb(0, 0, 42, 0.118) 0%, rgb(31, 31, 116) 20%);
  transform: skew(-0.6rad);
  margin-left: 35vw;
}

.contServicios2 {
  width: 70vh;
  height: 50vh;
  transform: skew(0.6rad);
  margin-left: 17vw;
  padding-top: 25vh;
  color: white;
  letter-spacing: 0.05vw;
}

/* Mobile css */

/* @media (max-height: 670px) {
  .buttonInicio {
    width: 30vw;
    font-size: 1.7vh;
  }

  .fraseInicio {
    font-size: 3vh;
  }

  #sec2 {
    height: 65vh;
    overflow-y: hidden;
  }

  #sec3 {
    height: 65vh;
    overflow-y: hidden;
  }

  #sec4 {
    height: 65vh;
    overflow-y: hidden;
  }

  .diagonal {
    width: 150vw;
    height: 80vh;
    margin-left: -36vw;
    background: linear-gradient(123deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 42) 65%, rgba(0, 212, 255, 0) 100%);
  }

  .diagonal2 {
    width: 150vw;
    height: 90vh;
    background-color: rgb(0, 0, 42);
    transform: skew(-0.6rad);
    margin-left: -27vw;
    background: linear-gradient(123deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 42) 65%, rgba(0, 212, 255, 0) 100%);
  }

  .contServicios {
    margin-left: 60vw;
  }

  .titleServicios {
    font-size: 5.5vh;
  }

  .titleServicio {
    font-size: 2.5vh;
  }

  .descServicio {
    width: 80vw;
    font-size: 1.5vh;
  }
} */

@media (max-width: 440px) {
  .buttonInicio {
    width: 35vw;
    font-size: 1.7vh;
  }

  .fraseInicio {
    font-size: 3vh;
  }

  #sec2 {
    height: 65vh;
    overflow-y: hidden;
  }

  #sec3 {
    height: 65vh;
    overflow-y: hidden;
  }

  #sec4 {
    height: 65vh;
    overflow-y: hidden;
  }

  .diagonal {
    width: 150vw;
    height: 80vh;
    margin-left: -36vw;
    background: linear-gradient(123deg, rgba(31,31,116,1) 18%, rgba(31,31,116,0.8295693277310925) 47%, rgba(31,31,116,0.6026785714285714) 66%, rgba(0,212,255,0) 100%);
  }

  .diagonal2 {
    width: 150vw;
    height: 90vh;
    transform: skew(-0.6rad);
    margin-left: -27vw;
    background: linear-gradient(123deg, rgb(31, 31, 116) 10%, rgba(0, 0, 42, 0.118) 100%, rgba(0, 212, 255, 0) 100%);
  }

  .contServicios {
    margin-left: 65vw;
  }
  
  .contServicios2 {
    margin-left: 6vw;
  }
  
  .titleServicios {
    font-size: 5.5vh;
  }

  .titleServicio {
    font-size: 2.5vh;
  }

  .descServicio {
    width: 80vw;
    font-size: 1.5vh;
  }
}