*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.background {
    display: flex;
    min-height: 90vh;
    background-color: #F8F8F8;
}

.container {
    flex: 0 1 55vw;
    margin: auto;
    padding: 10px;
}

.screen {
    position: relative;
    background: rgba(241, 240, 241, 0.607);
    border-radius: 15px;
    font-weight: 700;
    letter-spacing: 1.4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.screen:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    bottom: 0;
    border-radius: 15px;
    z-index: -1;
}

.screen-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: rgb(235, 234, 235);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.screen-header-left {
    margin-right: auto;
}

.screen-header-button {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 3px;
    border-radius: 8px;
    background: white;
}

.screen-header-button.close {
    background: red;
}

.screen-header-button.maximize {
    background: yellow;
}

.screen-header-button.minimize {
    background: #74c54f;
}

.screen-header-right {
    display: flex;
}

.screen-header-ellipsis {
    width: 3px;
    height: 3px;
    margin-left: 2px;
    border-radius: 8px;
    background: #999;
}

.screen-body {
    display: flex;
}

.screen-body-item {
    flex: 1;
    padding: 50px;
}

.screen-body-item.left {
    display: flex;
    flex-direction: column;
}

.app-title {
    display: flex;
    flex-direction: column;
    position: relative;
    color: rgb(87,139,208);
    font-size: 26px;
}

.app-title:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 25px;
    height: 4px;
    background: rgb(87,139,208);
}

.app-contact {
    margin-top: auto;
    font-size: 8px;
    color: #888;
}

.app-form-group {
    margin-bottom: 15px;
}

.app-form-group.message {
    margin-top: 40px;
}

.app-form-group.buttons {
    margin-bottom: 0;
    text-align: right;
}

.app-form-control {
    width: 100%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    color: #666;
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
    transition: border-color .2s;
}

.app-form-control::placeholder {
    color: #666;
}

.app-form-control:focus {
    border-bottom-color: #ddd;
}

.app-form-button {
    background: none;
    border: none;
    color: rgb(87,139,208);
    font-size: 14px;
    cursor: pointer;
    outline: none;
    margin-left: 1vw;
}

.app-form-button:hover {
    color: red;
}

@media screen and (max-width: 520px) {
    .screen-body {
        flex-direction: column;
    }

    .screen-body-item.left {
        margin-bottom: 30px;
    }

    .app-title {
        flex-direction: row;
    }

    .app-title span {
        margin-right: 12px;
    }

    .app-title:after {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .screen-body {
        padding: 40px;
    }

    .screen-body-item {
        padding: 0;
    }
}