.divAcc{
    display: flex;
    flex-direction: column;
    margin-top: 12vh;
    width: 60%;
}

@media (max-width: 414px) {
    .divAcc{
        margin-top: 12vh;
        width: 95%;
    }
}